<script>
    import Base from '@backend/Base.vue'
    import BOGen from '@helper/BOGen';
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import listPlugin from '@fullcalendar/list'
    import moment from 'moment';
    import * as XLSX from 'xlsx'; 

    moment.locale('en')
    moment
        .tz('Asia/Jakarta')
        .format()

    export default {
        extends: Base,
        components: {
            FullCalendar
        },
        data() {
            return {
                Name: "BoDashboard",
                site_name: "",
                activityEvent:{},
                holidayEvent:{},
                bulkData: {},
                labelDate:new Date()
                    .toISOString()
                    .slice(0, 10),
                minDatetime: new Date()
                    .toISOString()
                    .slice(0, 16),
                modal: false,
                showForm: false,
                showButtonAdd: true,
                showButtonDelete: false,
                dataByDate:[],
                input: {
                    ae_label: 'UMUM',
                    ae_allday: false,
                    ae_is_drop: 'N'
                },
                end_date: true,
                is_delete: false,
                calendarOptions: {
                    plugins: [
                        dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin
                    ],
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        right: 'dayGridMonth,listMonth'
                    },
                    initialView: 'dayGridMonth',
                    timeZone: 'UTC',
                    editable: false,
                    selectable: true,
                    selectMirror: true,
                    weekends: true,
                    select: this.handleDateSelect,
                    eventClick: this.handleEventClick,
                    eventsSet: this.handleEvents,
                    datesSet: this.handleMonthChange,
                    // droppable: false,
                    // eventResize: this.handleEventResize,
                    longPressDelay: 0,
                    events: [],
                    eventTimeFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    }
                },
                countRejected: 0,
                timeRejected: 0,
                efectiveRejected: 0,
                countApproved: 0,
                timeApproved: 0,
                efectiveApproved: 0,
                countNeedVerified: 0,
                timeNeedVerified: 0,
                efectiveNeedVerified: 0
            }
        },
        computed: {
            ufullname() {
                return this.user
                    ? this.user.bu_full_name
                    : "-"
            }
        },
        mounted() {
            this.$set(this.$root, 'page', this)
            this.fetchData()
        },
        methods: {
            fetchData(param = {}) {
                var params = Object.assign(param, this.$route.query)
                BOGen.apirest("/" + this.Name + (
                    this.$route.params.id
                        ? "/" + this.$route.params.id
                        : ""
                ), params, (err, resp) => {
                    this.holidayEvent = resp.holiday.map(holiday => ({
                        className: 'background_rejected',
                        title: holiday.ae_title,
                        start: holiday.ae_start_date,
                        allDay: true,
                        type: 'holiday',
                        backgroundColor: "#ff5722", // Warna berbeda
                        textColor: "#fff",
                    }))
                    this.bulkData = resp.alldata
                    this.activityEvent = resp.data.map(event => ({
                        className: 'background_utama',
                        title: event.total,
                        start: event.ae_start_at,
                        allDay: true
                    }));
                    this.calendarOptions.events = [...this.holidayEvent, ...this.activityEvent]
                })
            },

            handleMonthChange(info){
                this.fetchData({"curentdate":moment(info.view.currentStart).format('YYYY-MM-DD')})
            },

            handleWeekendsToggle() {
                this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
            },
            // Add
            handleDateSelect(selectInfo) {

                const holidayDate = selectInfo.startStr;
                const isHoliday = this.holidayEvent.some((holiday) => holiday.start === holidayDate);
                if(isHoliday){
                    return swal({icon: "warning", title: "National holiday cannot be selected."});
                }

                const selectedDate = new Date(selectInfo.start)
                    .toISOString()
                    .slice(0, 10);
                
                var timeNow = moment().tz("Asia/Jakarta").format('HH:mm:ss')
                this.input.ae_bu_id=this.user.bu_id
                this.input.ae_start_at=selectInfo.startStr+'T'+timeNow
                this.input.ae_end_at=selectInfo.endStr+'T'+timeNow
                    
                const day = selectInfo
                    .start
                    .toString()
                    .split(' ')[0]
                const now = new Date()
                    .toISOString()
                    .slice(0, 10);
                this.labelDate = moment(selectedDate).format('dddd, DD MMMM YYYY');
                if (day === 'Sat' || day === 'Sun') {
                    return swal({icon: "warning", title: "Weekends cannot be selected."});
                } else if (selectedDate < now) {
                    this.showButtonAdd = false 
                    this.showButtonDelete = false
                    this.openModal()
                } else {
                    this.input.type = "getByDate"
                    BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                        this.showButtonAdd = true 
                        this.showForm = false 
                        this.dataByDate = resp.data
                        this.openModal()
                    }, "POST")
                }
            },

            handleEventClick(clickInfo) {
                var selectedDate = new Date(clickInfo.event.start)
                    .toISOString()
                    .slice(0, 10);
                var startDate = {}
                startDate.start = new Date(moment(selectedDate).format('YYYY-MM-DD'));
                startDate.startStr = moment(selectedDate).format('YYYY-MM-DD');
                this.handleDateSelect(startDate)   
            },

            deleteEvent(id) {
                swal({
                    title: "Are you sure to delete " + this.ObjectName + "?",
                    text: "",
                    icon: "warning",
                    buttons: [
                        "No, Cancel!", "Yes, Sure!"
                    ],
                    dangerMode: true
                }).then((ok) => {
                    if (ok) {
                        BOGen.apirest("/" + this.Name, {
                            type: "delete",
                            id: id
                        }, (err, resp) => {
                            if (err) 
                                return swal(err.resp.message, "", "warning")
                            swal("Deleted!", resp.message, "success");
                            this.fetchData()
                            $('#eventModal').modal('hide')
                            this.is_delete = false
                        }, "POST")
                    }
                });
            },

            handleClickEdit(v){
                this.input = v
                this.input.ae_start_at = v.ae_start_at.split(" ")[0]+'T'+v.ae_start_at.split(" ")[1]
                this.input.ae_end_at = v.ae_end_at.split(" ")[0]+'T'+v.ae_end_at.split(" ")[1]
                this.input.ae_allday = v.ae_allday == 'Y' ? true : false
                this.input.ae_id = v.ae_id
                this.input.ae_title = v.ae_title
                this.input.ae_is_drop = "N"
                this.input.type = "editEvent"
                this.showForm = true;
            },

            openModal() {
                this.modal = true;
                $('#eventModal').modal({
                    backdrop: 'static',
                    keyboard: false 
                })
            },

            submitFormEvent(e, callback) {
                if (this.input.ae_id != null) 
                    return this.editEvent()
                let start_date = moment(this.input.ae_start_at)
                    .tz('Asia/Jakarta')
                    .format('YYYY-MM-DD HH:mm:ss');
                let end_date = moment(this.input.ae_end_at)
                    .tz('Asia/Jakarta')
                    .format('YYYY-MM-DD HH:mm:ss');
                if (e && e.btnLoading()) 
                    return;
                this.input.type = 'addEvent'
                this.input.ae_start_at = start_date;
                this.input.ae_end_at = end_date;
                this.input.ae_calculate_time = this.calculateWorkMinutes(start_date, end_date)
                BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                    if (e) 
                        e.btnUnloading()
                    if (err) {
                        if (err.status == 422) {
                            Object
                                .keys(err.responseJSON)
                                .forEach((k) => {
                                    $("[error=" + k + "]").html(
                                        "<label class='error'>" + err.responseJSON[k][0] + "</label>"
                                    )
                                })
                        }
                        return
                    }
                    if (resp.error) {
                        swal({icon: "warning", title: resp.message});
                    }
                    if (resp.success) {
                        if (this.successFormCallback) 
                            return this.successFormCallback(resp);
                        if (callback) 
                            return callback(resp);
                        this.refreshData()
                        this.fetchData()
                        swal({icon: "success", title: "Event added successfully"}).then((result) => {
                            $('#eventModal').modal('hide')
                            $('form')
                                .find('input, select, textarea')
                                .each(function () {
                                    $(this).val('');
                                });
                        })
                    }
                }, "POST")
            },
            editEvent() {
                let start_date = moment(this.input.ae_start_at)
                    .tz('Asia/Jakarta')
                    .format('YYYY-MM-DD HH:mm:ss');
                let end_date = moment(this.input.ae_end_at)
                    .tz('Asia/Jakarta')
                    .format('YYYY-MM-DD HH:mm:ss');
                this.input.ae_bu_id = this.user.bu_id
                this.input.type = 'editEvent'
                this.input.ae_start_at = start_date;
                this.input.ae_end_at = end_date;
                this.input.ae_calculate_time = this.calculateWorkMinutes(this.input.ae_start_at, this.input.ae_end_at)
                BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                    if (err) {
                        if (err.status == 422) {
                            Object
                                .keys(err.responseJSON)
                                .forEach((k) => {
                                    $("[error=" + k + "]").html(
                                        "<label class='error'>" + err.responseJSON[k][0] + "</label>"
                                    )
                                })
                        }
                        return
                    }
                    if (resp.error) {
                        swal({icon: "warning", title: resp.message});
                        this.refreshData()
                        this.fetchData()
                    }
                    if (resp.success) {
                        this.refreshData()
                        this.fetchData()
                        swal({icon: "success", title: resp.message}).then((result) => {
                            $('#eventModal').modal('hide')
                        })
                    }
                }, "POST")
            },
            addButtonEvent () {
                this.showForm = true
                var timeNow = moment().tz("Asia/Jakarta").format('HH:mm:ss')
                this.input.ae_bu_id=this.user.bu_id
                this.input.ae_id = null
                this.input.ae_title = ''
                this.input.ae_description = ''
                this.input.type = 'addEvent'
            },
            approvalStatus(v){
                swal({
                    text: "What would you like to do?",
                    icon: "warning",
                    buttons: {
                        approve: {
                        text: "Approve", 
                        value: "approve",
                        visible: true,   
                        className: "btn-success", 
                        },
                        reject: {
                        text: "Reject",
                        value: "reject",
                        visible: true,   
                        className: "btn-danger",
                        },
                        cancel: {
                        text: "Cancel", // Label for the cancel button
                        value: "cancel",
                        visible: true,   
                        className: "btn-cancel", 
                        },
                    },
                    }).then((value) => {
                    switch (value) {
                        case "approve":
                        this.changeApprovalHandler(v, "Y");
                        break;
                        case "reject":
                        this.changeApprovalHandler(v, "R");
                        break;
                        default:
                        console.log("No action taken.");
                    }
                    });
            },
            changeApprovalHandler(row,value) {
                this.input = row;
                this.input.ae_is_active = value;
                this.input.type = "approvalStatus";
                BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
                    if (err) {
                        if (err.status == 422) {
                            Object
                                .keys(err.responseJSON)
                                .forEach((k) => {
                                    $("[error=" + k + "]").html(
                                        "<label class='error'>" + err.responseJSON[k][0] + "</label>"
                                    )
                                })
                        }
                        return
                    }
                    if (resp.error) {
                        swal({icon: "warning", title: resp.message});
                        this.refreshData()
                        this.fetchData()
                    }
                    if (resp.success) {
                        this.refreshData()
                        this.fetchData()
                        this.dataByDate = resp.dataByDate
                        swal({icon: "success", title: resp.message});
                    }
                }, "POST")
            },
            closeModal() {
                this.modal = false;
                this.showForm = false;
                this.dataByDate = [];
                $('form')
                    .find('input, select, textarea')
                    .each(function () {
                        $(this).val(' ');
                    });
                $('#eventModal').modal('hide')
            },
            calculateWorkMinutes(startDate, endDate) {
                const startWorkHour = 8;  // Jam mulai kerja (08:00)
                const endWorkHour = 16;  // Jam selesai kerja (16:00)

                // Konversi startDate dan endDate ke moment
                let start = moment(startDate);
                let end = moment(endDate);

                // Jika start lebih awal dari jam kerja, atur ke jam kerja (08:00)
                if (start.hour() < startWorkHour) {
                    start = start.set({ hour: startWorkHour, minute: 0, second: 0 });
                }

                // Jika end lebih larut dari jam kerja, atur ke jam kerja (16:00)
                if (end.hour() >= endWorkHour) {
                    end = end.set({ hour: endWorkHour, minute: 0, second: 0 });
                }

                // Jika start dan end berada di hari yang sama, kalkulasi langsung
                if (start.isSame(end, 'day')) {
                    return Math.max(0, end.diff(start, 'minutes'));
                }

                // Jika start dan end berada di hari yang berbeda, lakukan iterasi harian
                let totalMinutes = 0;

                while (start.isBefore(end, 'day') || start.isSame(end, 'day')) {
                    // Ambil waktu akhir hari ini
                    const dayEnd = start.clone().set({ hour: endWorkHour, minute: 0, second: 0 });

                    // Jika ini adalah hari terakhir, gunakan end sebagai waktu akhir
                    if (start.isSame(end, 'day')) {
                    totalMinutes += Math.max(0, end.diff(start, 'minutes'));
                    break;
                    } else {
                    // Tambahkan waktu kerja penuh untuk hari ini
                    totalMinutes += dayEnd.diff(start, 'minutes');
                    }

                    // Pindah ke hari berikutnya pada jam kerja (08:00)
                    start = start.add(1, 'day').set({ hour: startWorkHour, minute: 0, second: 0 });
                }

                return totalMinutes;
            },
            downloadReport() {
                const eventData = this.bulkData.map((event) => ({
                    Title: event.ae_title,
                    Start: event.ae_start_at,
                    End: event.ae_end_at,
                    AllDay: event.ae_allday == 'Y' ? 'Full day' : '-',
                    Status: event.ae_status == 'Y' ? 'Approved' : event.ae_status == 'N' ? 'Need Approved' : 'Rejected',
                }));

                // Buat WorkSheet (SheetJS)
                const worksheet = XLSX.utils.json_to_sheet(eventData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Events');

                // Simpan ke file Excel
                XLSX.writeFile(workbook, 'Activities-Report.xlsx');
            }
        }
    };
</script>
<style>
    .background_utama {
        color: #2e69fd !important;
        background: #9bd0ffdb !important;
        border-radius: 4px !important;
    }
    .background_utama .fc-event-main {
        color: #2e69fd !important;
    }
    .background_utama:hover {
        color: #2e69fd !important;
        background: #9bd0ff87 !important;
        border-radius: 4px !important;
    }
    .background_umum {
        color: #4c9c12 !important;
        background: #b4f964e6 !important;
        border-radius: 4px !important;
    }

    .background_umum .fc-event-main {
        color: #4c9c12 !important;
    }
    .background_umum:hover {
        color: #4c9c12 !important;
        background: #b4f964ad !important;
        border-radius: 4px !important;
    }
    .background_rejected {
        color: #c00d0d !important;
        background: #f44336a3 !important;
        border-radius: 4px !important;
        text-decoration: line-through!important;
    }
    .background_rejected .fc-event-main {
        color: #c00d0d !important;
    }
    .background_rejected:hover {
        color: #c00d0d !important;
        background: #f44336b3 !important;
        border-radius: 4px !important;
        text-decoration: line-through!important;
    }
    .background_rejected span,
    .background_umum span,
    .background_utama span {
        margin-left: 10px;
    }
    .modal.fade .modal-dialog {
        transform: translateX(-100%);
        transition: transform 0.3s ease-out;
    }

    .modal.fade.show .modal-dialog {
        transform: translateX(0);
    }
    .vdatetime-input {
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 7px;
        color: #746f6f;
    }
    .fc-day-sat,
    .fc-day-sun {
        background-color: #ffcccc4f !important;
    }
    .fc-day-sat .fc-event,
    .fc-day-sun .fc-event {
        color: #333;
    }
    .calendar-event,
    .fc-event {
        font-weight: bold;
    }
    .header-primary {
        background-color: #3f51b5;
        color: #fff;
    }
    @media (max-width: 767px) {
        .fc .fc-toolbar-title {
            font-size: 0.8em;
            margin: 0px;
        }
    }
</style>
<template>
    <div class="container-fluid">
        <!-- <PageTitle></PageTitle> -->
        <div class="row">
            <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Event Calendar</h5>
                        <FullCalendar :options='calendarOptions' @datesRender="handleMonthChange">
                            <!-- <template v-slot:eventContent='arg'> <b>{{ arg.timeText }}</b><span>{{
                            arg.event.title }}</span> </template> -->
                        </FullCalendar>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-header bg-info text-white">
                        All Activities & Times
                    </div>
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Activities</td>
                                        <td>{{ ( countApproved + countRejected + countNeedVerified) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Input Times</td>
                                        <td>{{ (parseInt(timeApproved) + parseInt(timeRejected) + parseInt(timeNeedVerified)) }} Minutes</td>
                                    </tr>
                                    <tr>
                                        <td>Efective Times</td>
                                        <td>{{ (parseInt(efectiveApproved) + parseInt(efectiveNeedVerified) + parseInt(efectiveRejected)) }} Minutes</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer" v-if="bulkData.length > 0">
                        <button class="btn btn-sm btn-success btn-block" @click="downloadReport"><i class="fa fa-download" ></i> Download Report</button>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header bg-success text-white">
                        Approved
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Activities</td>
                                        <td>{{ countApproved }}</td>
                                    </tr>
                                    <tr>
                                        <td>Input Times</td>
                                        <td>{{ timeApproved }} Minutes</td>
                                    </tr>
                                    <tr>
                                        <td>Efective Times</td>
                                        <td>{{ efectiveApproved }} Minutes</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header bg-danger text-white">
                        Rejected
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Activities</td>
                                        <td>{{ countRejected }}</td>
                                    </tr>
                                    <tr>
                                        <td>Input Times</td>
                                        <td>{{ timeRejected }} Minutes</td>
                                    </tr>
                                    <tr>
                                        <td>Efective Times</td>
                                        <td>{{ efectiveRejected }} Minutes</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header bg-info text-white">
                        Need Approval
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Activities</td>
                                        <td>{{ countNeedVerified }}</td>
                                    </tr>
                                    <tr>
                                        <td>Input Times</td>
                                        <td>{{ timeNeedVerified }} Minutes</td>
                                    </tr>
                                    <tr>
                                        <td>Efective Times</td>
                                        <td>{{ efectiveNeedVerified }} Minutes</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Modal Form -->
        <div
            class="modal fade"
            id="eventModal"
            tabindex="-1"
            aria-labelledby="eventModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header header-primary">
                        <h5 class="modal-title" id="eventModalLabel">{{labelDate}}</h5>
                        <a href="javascript:;" class="text-white" @click="closeModal">Close</a>
                    </div>
                    <VForm @resp="submitFormEvent" v-if="showForm">
                        <div class="modal-body">
                            <BoField name="ae_title" v-model="input.ae_title" mandatory="mandatory"></BoField>
                            <div class="row">
                                <div class="col-md-4">
                                    <BoField :class="'mb-0'" name="ae_start_at" mandatory="mandatory">
                                        <datetime
                                            type="datetime"
                                            :format="'yyyy-MM-dd HH:mm:ss'"
                                            v-model="input.ae_start_at"
                                            input-class="my-class"
                                            :phrases="{ok: 'Continue', cancel: 'Exit'}"
                                            :min-datetime="minDatetime"
                                            :week-start="7"
                                            use24-hour="use24-hour"
                                            auto="auto">
                                        </datetime>
                                    </BoField>
                                </div>
                                <div class="col-md-4" v-if="!input.ae_allday">
                                    <BoField :class="'mb-0'" name="ae_end_at" mandatory="mandatory">
                                        <datetime
                                            style="width: 100%"
                                            type="datetime"
                                            :format="'yyyy-MM-dd HH:mm:ss'"
                                            v-model="input.ae_end_at"
                                            input-class="my-class"
                                            :phrases="{ok: 'Continue', cancel: 'Exit'}"
                                            :week-start="7"
                                            use24-hour="use24-hour"
                                            auto="auto">
                                        </datetime>
                                    </BoField>
                                </div>
                                <div class="col-4">
                                    <BoField :class="'mb-0'" name="ae_label">
                                        <div class="row">
                                            <radio
                                                name="ae_label"
                                                v-model="input.ae_label"
                                                option="UMUM"
                                                :attr="validation('ae_label')">Umum</radio>
                                            <radio name="ae_label" v-model="input.ae_label" option="UTAMA">Utama</radio>
                                        </div>
                                    </BoField>
                                </div>
                            </div>

                            <!-- Checkbox Full Day -->
                                <div class="form-check mb-3">
                                    <input
                                    class="form-check-input"
                                    v-model="input.ae_allday"
                                    type="checkbox"
                                    id="fullday"
                                    name="fullday">
                                    <label class="form-check-label" for="fullday">Event All Day</label>
                                </div>

                                <!-- Textarea Description -->
                                <div>
                                    <BoField name="ae_description">
                                        <textarea
                                            class="form-control"
                                            id="ae_description"
                                            v-model="input.ae_description"
                                            rows="3"
                                            :attr="validation('ae_description')"
                                            placeholder="Deskripsi singkat tentang event"></textarea>
                                    </BoField>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                                <button type="submit" class="btn btn-info btn-loading">Save Event</button>
                            </div>
                    </VForm>
                    <div class="modal-body" v-else>
                        <div class="text-right mb-3">
                            <button v-if="showButtonAdd" type="button" class="btn btn-info" @click="addButtonEvent()">
                                <i class="ti-plus"></i> Add Activity</button>
                        </div>
                        <div class="table-responsive ajax-table">
                            <table class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Activity</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Status</th>
                                        <th width="120px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="dataByDate.length > 0">
                                        <tr v-for="(v,k) in dataByDate" :key="k" >
                                            <td class="number">{{k+1}}</td>
                                            <td>{{ v.ae_title }} </td>
                                            <td>{{ (v.ae_start_at||"").dates('format2') }}</td>
                                            <td>{{ (v.ae_end_at||"").dates('format2') }}</td>
                                            <td>
                                                <p class="mb-0" v-if="v.ae_allday == 'Y'"><StatusLabel :status="v.ae_allday" :type="'allday'"></StatusLabel></p>
                                                <p class="mb-0"><StatusLabel :status="v.ae_label" :type="'label'"></StatusLabel></p>
                                                <p class="mb-0"><StatusLabel :status="v.ae_is_active" :type="'approve'"></StatusLabel></p>
                                            </td>
                                            <td>
                                                <template v-if="user.bu_level != 3">
                                                    <button v-if="v.ae_is_active == 'N'" class="icon_action btn-info ml-1 "  @click="approvalStatus(v)" v-tooltip="'Approve'">
                                                        <i class="ti-check"></i>
                                                    </button>
                                                </template>
                                                <button @click="handleClickEdit(v)" class="icon_action btn-success ml-1" v-tooltip="'Edit'">
                                                    <i class="ti-marker-alt"></i>
                                                </button>
                                                <button @click="deleteEvent(v.ae_id)" class="icon_action btn-danger ml-1" v-tooltip="'Delete'">
                                                    <i class="ti-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else >
                                        <td colspan="6" align="center"> No Data </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>